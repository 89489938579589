import {GLOBAL_EVENT} from "../../_global/js/framework/enum/GLOBAL_EVENT.js";
import CalendarTag    from "./component/CalendarTag.js";
import Calendar       from "./model/Calendar.js";


export default class HomeController {
    public static CALENDAR: Calendar;
    public static readonly MAIN: HTMLElement = document.getElementsByTagName("main")[0];


    public constructor() {
        window.customElements.define("calendar-tag", CalendarTag);

        HomeController.CALENDAR = new Calendar();
        HomeController
            .CALENDAR
            .addEventListener(
                [GLOBAL_EVENT.onData],
                () => {
                    const CALENDAR_TAG = new CalendarTag(HomeController.CALENDAR);

                    HomeController
                        .MAIN
                        .appendChild(CALENDAR_TAG);
                }
            );
    }
}
